import React from "react"
import LayoutComponent from "../../components/Layout.js"
import Nuklearmedicin from "../../components/Nuklearmedicin.js"
import Knap from "../../components/Knap.js"

const NuklearmedicinPage = () => (
    <LayoutComponent>
        <h1>Nuklearmedicin</h1>
        <p>Nu förtiden kan man inte längre säga röntgentillbehör eller röntgenskydd utan att också nämna nuklearmedicin. Härtill har vi knutit vår mångåriga erfarenhet med försäljning av röntgentillbehör till att också inkludera röntgenskydd i form av blyskydd, blyförkläder, röntgenglasögon samt träningsfantomer, kvalitetskontroll (QA), patientsimulatorer och mjukdelssimulatorer till nuklearmedicin.</p>
        <h1>Våra leverantörer</h1>
        <Nuklearmedicin />
        <Knap />
    </LayoutComponent>
)
export default NuklearmedicinPage